@import "../_app.scss";

@import '../main.scss';


$main_color: #07c66d;

$button_color: #428bca;

/**/
.text-colour_primary {
  color: $main_color;
}

header {
  background-color: $main_color;
}

form.search input[type="submit"] {
  background-color: $button_color;
}

.breadcrumbs span {
  color: $main_color;
}

.columns li .title {
  color: $main_color;
}

.helpful .option {
  color: $main_color;
}

.related a{
  color: $main_color;
}


#footer-inner .logo {
  width: 100%;
  float: right;
  margin-top: 50px;
  height: 44px;
  background: image-url('_yesbackup/logo-header.png') no-repeat 0 0;
}

#header .menu a {
  padding: 10px;
  text-transform: uppercase;
  font-family: open-sans, arial, sans-serif;
  font-size: 14px;
  color: #7a7a7a;
}

/**/

#content {
  border-top: 1px solid #dfdfdf;
  padding: 40px 0 50px;
}

/**/

#footer {
  background: #fff image-url('_yesbackup/bg-footer.png') repeat-x 0 0;
}

.greybg {
  background-color: #f0f0f0 !important;
}

/**/
#baseFooter {
  padding: 0;
  float: right;
  width: 30%;
  border: none;
  margin: 0;
}

.title {
  &.large {
    font-size: 30px;
    font-weight: normal;
    font-family: Helvetica, arial, sans-serif;
    margin: 20px auto 15px auto;
    text-align: center;
  }

  font-size: 30px;
  font-weight: normal;
  font-family: Helvetica, arial, sans-serif;
  margin: 20px auto 15px auto;
  text-align: center;

  &.small {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
  }
}

.heading {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 15px;
}

.subtitle {
  color: #737373;
  font-size: 17px;
  text-align: center;
}

.pageIntro {
  margin-bottom: 20px;
}

body{
  #footer-inner {
    background: #302e30;
    color: #727273;
    padding: 22px 0 65px;
    margin-top: 40px;

    #copyright {
      font-size: 13px;
    }
  }
}

#footer-inner .menu .link-title {
  padding: 0 0 5px;
  text-transform: uppercase;
  font-size: 16px;
  color: #666262;
}

#baseFooter {
  padding: 0;
  float: right;
  width: 30%;
  border: none;
  margin: 0;
}

#footer-inner #footerMenus {
  display: inline;
}
